<template>
  <div id="cms-entry-create">
    <b-container fluid>
      <b-row>
        <b-col></b-col>
        <b-col><h2 style="color: white; margin-bottom: 1em">Entry - Add</h2></b-col>
        <b-col></b-col>
      </b-row>
      <b-row>
        <b-col>
          <SideMenu></SideMenu>
        </b-col>
        <b-col class="col-sm-9">
          <div class="col-sm-10" style="color: white; text-align: right">
            <b-row>
              <b-col class="col-sm-5" style="margin-left: 6em">
                <b-form-group
                        label-cols-sm="1"
                        label-cols-lg="1"
                        label="Type:"
                >
                  <b-form-select
                          v-model="entryType"
                          :options="entryTypes"
                          value-field="name"
                          text-field="name"
                          style="margin-left: 3em"
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                        label-cols-sm="1"
                        label-cols-lg="1"
                        label="Date:"
                        label-for="date"
                >
                  <b-form-input id="date" type="date" v-model="entry.date" style="margin-left: 3em"></b-form-input>
                </b-form-group>
                <b-form-group
                        label-cols-sm="1"
                        label-cols-lg="1"
                        label="Amount:"
                        label-for="amount"
                >
                  <b-form-input id="amount" type="number" v-model="entry.amount" placeholder="0.00" style="margin-left: 3em"></b-form-input>
                </b-form-group>
                <b-form-group
                        label-cols-sm="1"
                        label-cols-lg="1"
                        label="Remark:"
                        label-for="remark"
                >
                  <b-form-input id="remark" type="text" v-model="entry.remark" placeholder="Optional" style="margin-left: 3em"></b-form-input>
                </b-form-group>
                <router-link class="link-color" :to="{ name: 'CmsEntries' }" @click.prevent>
                  <b-button type="reset" variant="danger">Cancel</b-button>
                </router-link>
                <b-button  @click.prevent="addEntry()" type="submit" variant="success">Submit</b-button>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import SideMenu from '@/components/SideMenu.vue'
  import axios from "axios";

  export default {
    data() {
      return {
        entry:
            {
              date: '',
              type: '',
              amount: null,
              remark: null
            },
        entryType: '',
        entryTypes: []
      }
    },
    components: {
      SideMenu
    },
    beforeCreate () {
      this.axios
          .get('https://batavia-backend.herokuapp.com/api/entry-type')
          .then(response => (
              this.entryTypes = response.data
          ))
    },
    methods: {
      addEntry() {
        this.axios
            .post(
                'https://batavia-backend.herokuapp.com/api/entry',
                {
                  date: this.entry.date,
                  type_id: this.getEntryTypeId(),
                  amount: this.entry.amount,
                  remark: this.entry.remark
                },
                {
                  headers: {
                    Authorization: axios.defaults.headers.common.Authorization
                  }
                }
            )
            .then(() => {
              this.$router.push({ name: 'CmsEntries' })
            })
            .catch(error => {
              console.log(error)
            })
      },
      getEntryTypeId() {
        let entryTypeName = this.entryType
        let entryTypeId = null

        this.entryTypes.map(function (entryType) {
          if (entryType['name'] === entryTypeName) {
            entryTypeId = entryType['id']
          }
        })
        console.log(entryTypeId)
        return entryTypeId
      }
    }
  }
</script>